<template>
    <section
        v-if="useIsVisible(blok)"
        v-editable="blok"
        :class="
            getStyleSettings(blok) + ' ' + [blok.hide ? useIsEditorClass() : '']
        "
    >
        <div class="container max-w-[1040px]">
            <component
                :is="blok.headline_html"
                v-if="blok.headline"
                class="text-h2 mb-6 max-w-[800px] lg:mb-10"
                :class="textColor"
            >
                {{ blok.headline }}
            </component>
            <div
                v-if="blok.text"
                :class="textColor"
                class="text-copy insights-text max-w-[800px]"
            >
                <v-runtime-template
                    :template="renderRichtext(blok.text)"
                ></v-runtime-template>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { InsightsTextStoryblok } from '@/types/component-types-sb'
import VRuntimeTemplate from 'vue3-runtime-template'

const props = defineProps<{
    blok: InsightsTextStoryblok
}>()
const textColor = computed(() => {
    return props.blok.background_color === 'black' ? 'text-white' : 'text-black'
})
</script>
<style>
.insights-text.text-white {
    color: #fff;
    span {
        color: #fff !important;
    }
    ul li {
        span {
            color: #fff !important;
        }
        &:before {
            background-color: #fff;
        }
    }
}

.insights-text p {
    @apply mb-4 last:mb-0;
}
.insights-text ul li {
    padding-left: 40px;
    position: relative;
    margin-bottom: 8px;

    @media (min-width: 1024px) {
        margin-bottom: 16px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        background-color: #000;
        display: inline-block;
        width: 20px;
        height: 2px;

        @media (min-width: 1024px) {
            top: 14px;
        }
    }
}
</style>
